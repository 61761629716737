import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView h="100vh" image={`url(${mobileHero}) center / cover no-repeat`}>
          <Header />
          <CFView column center w="100%" textCenter>
            <CFImage
              w="100%"
              src={mobileHeroText}
              alt="Sushi Topia hero text"
              zIndex={98}
            />
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="90vh"
          maxHeight="690px"
          w="100%"
          maxWidth="1400px"
          w="100%"
          image={`url(${hero}) 40% 0% / cover no-repeat`}
          zIndex={90}
          column
        >
          <CFView column justifyCenter alignStart h="100%" ml="80px">
            <CFImage
              w="40%"
              maxWidth="395px"
              src={heroText}
              alt="Sushi Topia hero text"
              zIndex={98}
            />
            <CFView pulsate mt="30px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
